@font-face {
font-family: '__TTCommons_72a14e';
src: url(/_next/static/media/d22d113a4c8d8164-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__TTCommons_72a14e';
src: url(/_next/static/media/3c6bfe07798d7d53-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__TTCommons_72a14e';
src: url(/_next/static/media/b9776fbd5cef6edd-s.p.woff2) format('woff2');
font-display: swap;
font-weight: bold;
font-style: normal;
}

@font-face {
font-family: '__TTCommons_72a14e';
src: url(/_next/static/media/7672368ab7b779e6-s.p.woff2) format('woff2');
font-display: swap;
font-weight: bold;
font-style: italic;
}@font-face {font-family: '__TTCommons_Fallback_72a14e';src: local("Arial");ascent-override: 102.81%;descent-override: 25.70%;line-gap-override: 0.00%;size-adjust: 97.27%
}.__className_72a14e {font-family: '__TTCommons_72a14e', '__TTCommons_Fallback_72a14e'
}.__variable_72a14e {--TTCommons: '__TTCommons_72a14e', '__TTCommons_Fallback_72a14e'
}

